import Error from '../Error';

import styles from './NotFound.module.css';

const NotFoundPage = ({ children }) => (
  <div className={styles.container}>
    <Error message='The URL appears to be incorrect or the page may have moved' />
    {children}
  </div>
);

export default NotFoundPage;
