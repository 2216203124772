import React from 'react';

import {
  CustomerSupport,
  Heading,
  Icon,
  Link,
  Paragraph
} from 'operation-diameter';

import styles from './Error.module.css';

/**
 * Error
 * @param {string} message          Info message for the error
 * @param {boolean} showCustomerSupport       Show customer support component
 * @param {string} customerSupportEmail       Customer support email
 * @param {boolean} showCta                  Show call to action
 * @param {string} ctaLabel                  Call to action label
 * @param {string} ctaUrl                    Call to action URL
 * @param {*} error                 Uncaught error
 */
const Error = ({
  message,
  showCustomerSupport = false,
  customerSupportEmail = 'contactus@fundingcircle.com',
  showCta = false,
  ctaLabel = 'Refresh page',
  ctaUrl = ''
}) => (
  <div data-testid='error' className={styles.container}>
    <Icon icon='error' size='32' isDecorative />
    <Heading alt headingSize='h2'>
      Something went wrong...
    </Heading>
    <Paragraph textColor='--color-grey-500'>{message}</Paragraph>
    {showCta && (
      <Link href={ctaUrl} styledAsButton additionalClassnames={styles.margin}>
        {ctaLabel}
      </Link>
    )}
    {showCustomerSupport && (
      <CustomerSupport
        data-testid='customer-support'
        email={customerSupportEmail}
        additionalClassnames={styles.margin}
      />
    )}
  </div>
);

export default Error;
