import React from 'react';
import cx from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';

import { Form as DiameterForm } from 'operation-diameter';

const defaultConfig = {
  mode: 'onSubmit'
};

/**
 * @param {string} className      Extra class name to add to the form
 * @param {function} onSubmit     Function that will be ran upon form submission
 * @param {object} config         useForm config - https://react-hook-form.com/api/useform
 * @param {HTMLElement} children  HTML elements to be added into the form's content
 * @param {string} name           Name to be passed down to the DiameterForm
 * @return {HTMLElement}
 */
const Form = ({
  defaultValues,
  className,
  onSubmit,
  config,
  children,
  name = 'Form'
}) => {
  const methods = useForm({ ...defaultConfig, defaultValues, ...config });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <DiameterForm
        onSubmit={handleSubmit(onSubmit)}
        additionalClassnames={cx(className)}
        name={name}
      >
        {React.Children.map(
          children,
          (child) => child && React.cloneElement(child)
        )}
      </DiameterForm>
    </FormProvider>
  );
};

export default Form;
