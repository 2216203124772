import React from 'react';
import cx from 'classnames';

import styles from './PageError.module.css';
import Error from '../Error';

/**
 * PageError
 * @param {string} message           Info message for the page
 * @param {string} className         className custom class name to add to the PageError component
 * @param {*} children               Content
 */
const PageError = ({ message, className, children }) => (
  <div data-testid='page-error' className={cx(styles.pageError, className)}>
    <Error {...(message && { message: message })} />
    {children}
  </div>
);

export default PageError;
